import { useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import styled from "styled-components"

import { useTimeout } from "usehooks-ts"

import {
  useAnnounceWs,
  useBrokerWs,
  useDeviceCommand,
  useDeviceDataTunnel,
} from "src/components/RemoteDebugService/minutWsHooks"
import {
  BROKER_ID,
  BrokerCommand,
  IBrokerRequest,
  IBrokerResponse,
} from "src/components/RemoteDebugService/minutWsProtocol"
import { SerialDeviceConnectButton } from "src/components/RemoteDebugService/SerialDeviceConnectButton"
import { useSerial } from "src/components/RemoteDebugService/useSerial"
import { ChatDemo } from "src/components/Sandbox/ChatDemo"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { CopyBox } from "src/ui/CopyText/CopyBox"
import { MDetails } from "src/ui/ExpandableSection/MDetails"
import Amarillo from "src/ui/icons/illustrations/SIDE_USB_20.png"
import { MainContentBox } from "src/ui/Layout/MainContentBox"
import { PageTitle } from "src/ui/Layout/PageTitle"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { debug } from "src/utils/logger"

export function RemoteDebugClientUI() {
  return (
    <MainContentBox>
      <PageTitle>Remote Debug Service</PageTitle>
      <DeviceLinkSetupUI />
    </MainContentBox>
  )
}

export function DeviceLinkSetupUI() {
  const { isConnected: serialConnected, setAutoConnectEnabled } = useSerial()
  const { user_id: client_id } = useGetUser()
  const [deviceCmdUri, setDeviceCmdUri] = useState("")
  const [deviceDataUri, setDeviceDataUri] = useState("")
  const [currentReq, setCurrentReq] = useState("")
  const [showChat, setShowChat] = useState(false)
  const [error, setError] = useState<null | React.ReactNode>(null)
  const [waitingForReply, setWaitingForReply] = useState<number | null>(null)

  useEffectOnce(() => {
    setAutoConnectEnabled(true)
  })

  const { sendAnnounce } = useAnnounceWs({ connect: true })

  function onBrokerMessage(m: IBrokerResponse) {
    debug.log("broker message", m)
    if (isConnectSuccess(currentReq, client_id, m)) {
      const cmdChannel: string | undefined = m.data?.cmd
      const dataChannel: string | undefined = m.data?.data
      if (cmdChannel) {
        setDeviceCmdUri(cmdChannel ?? "")
        setDeviceDataUri(dataChannel ?? "")
        sendAnnounce({ client_id, cmdChannel, dataChannel })
      }
      setCurrentReq("")
      setWaitingForReply(null)
      setError(null)
    }
  }

  const brokerWs = useBrokerWs({
    uid: client_id,
    onMessage: onBrokerMessage,
    connect: true,
  })

  useDeviceCommand({
    uid: client_id,
    uri: deviceCmdUri,
    onMessage(r) {
      // eslint-disable-next-line no-console
      console.log(JSON.stringify(r.data, null, 2))
    },
    onDeviceDataUri(s) {
      setDeviceDataUri(s)
    },
  })

  // Setup tunnel between device and Minut Debug Service once a URI is available
  useDeviceDataTunnel({ uid: client_id, uri: deviceDataUri })

  function establishRemoteLink() {
    const channelCreateReq: IBrokerRequest = {
      cmd: BrokerCommand.CONNECT,
      src: client_id,
      dest: BROKER_ID,
      req_id: `connect-${client_id}`,
    }
    setCurrentReq(channelCreateReq.req_id)
    brokerWs.sendJsonMessage(channelCreateReq)
    setWaitingForReply(4000)
  }

  useTimeout(() => {
    setError("Unable to connect to debug service. Please try again later!")
  }, waitingForReply)

  const views = [
    {
      view: (
        <div key="instructions">
          <MText variant="heading3">Preparations</MText>
          <ol>
            <li>Ensure that your device is ON.</li>
            <li>Plug USB-C cable into your sensor.</li>
            <li>Plug other end of cable into your computer.</li>
            <li>
              Click <kbd>Connect to device</kbd>.
            </li>
          </ol>
        </div>
      ),
      show: !deviceCmdUri,
    },
    {
      view: (
        <div key="active-session">
          <p>
            A Minut engineer has been notified that you have connected to the
            Remote Debug Tool, and they will take a look at your device shortly.
          </p>
          <p>Do not close this window until the debugging session is over!</p>
          <CopyBox text={deviceCmdUri} />
          <br></br>
        </div>
      ),
      show: !!deviceCmdUri && !!serialConnected,
    },
    {
      view: (
        <div key="connect">
          <SerialDeviceConnectButton
            onConnected={establishRemoteLink}
            onDisconnected={() => {
              brokerWs.getWebSocket()?.close()
              setDeviceCmdUri("")
              setError(null)
              setWaitingForReply(null)
            }}
            loading={!!waitingForReply && !error}
          />
        </div>
      ),
      show: true,
    },
    {
      view: <MBanner key="errorbox">{error}</MBanner>,
      show: !!error,
    },
  ] as const

  // XXX: We should attach a listener to prevent the user from accidentally
  // closing the window or navigating away.
  return (
    <DeviceLinkSetupBox>
      <LazyLoadImage
        src={Amarillo}
        alt="responder"
        effect="opacity"
        style={{ maxHeight: "200px" }}
      />
      {views.filter((v) => !!v.show).map((v) => v.view)}

      <MDetails
        id="client-chatbox"
        title={"Chat"}
        open={showChat}
        onChange={() => setShowChat((s) => !s)}
      >
        <ChatDemo />
      </MDetails>
    </DeviceLinkSetupBox>
  )
}

const DeviceLinkSetupBox = styled.div`
  margin: ${spacing.XL} 0;
  display: grid;
  gap: ${spacing.L};
`

function isConnectSuccess(reqId: string, clientId: string, r: IBrokerResponse) {
  const s = r.req_id === reqId && r.dest === clientId && r.status === 200
  debug.log({ reqId, rReqId: r.req_id, rdest: r.dest, clientId })
  return s
}
